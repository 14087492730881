header {
  width: 100%;
}
.mainTitle {
  display: inline;
}

.pointer {
  cursor: pointer;
}
.highlight {
  background-color: #ffff99;
}

footer {
  width: 100%;
}

a,
i,
.ui.tabular.menu .item:not(.active) {
  cursor: pointer;
}

